<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0 d-flex">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("National contracts") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="min-w-300px mb-0 mr-0" :debounce="300">
        </euro-input>
        <b-button variant="light-primary" block class="font-weight-bolder"
          @click="createItemClick('national-contract-modal')">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Files/File.svg" />
          </span>
          {{ $t("Add New Contract") }}
        </b-button>
      </div>
    </div>
    <div class="card-body">
      <datatable ref="table" :fields="fields" :table-props="tableProps" :total="total" :options.sync="tableOptions"
        :filter="searchFilter" :per-page-options="perPageOptions">
        <template #[`header.country`]>
          {{ $t("NATIONAL LEGISLATION") }}
        </template>

        <template #[`cell.country`]="{ item }">
          <div class="h-100">
            <country-flag :country-iso="item.country"></country-flag>
          </div>
        </template>
        <template #[`cell.language`]="{ item }">
          <div class="h-100">{{ extendedLanguageName(item.language) }}</div>
        </template>

        <template #[`cell.actions`]="{ item }">
          <div class="d-flex">
            <b-button v-b-tooltip.hover :title="$t('Go to National contract levels')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
              @click="$router.push({ name: 'national-contract-level', params: { ID: item.id } })">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Navigation/Right-2.svg" />
              </span>
            </b-button>
            <b-button v-b-tooltip.hover :title="$t('Edit')" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
              @click="editItemClick('national-contract-modal', item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
            </b-button>
            <b-button v-b-tooltip.hover :title="$t('Delete')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
              @click="remove(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/General/Trash.svg" />
              </span>
            </b-button>
          </div>
        </template>
      </datatable>
      <NationalContractModal :existing-form="editForm" @refresh="refresh">
      </NationalContractModal>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import Datatable from "@/view/components/tables/Datatable.vue";
import CountryFlag from "@/view/components/CountryFlag.vue";
import EmployeeNationalContractService from "@/core/services/employee/employee-nationalcontract.service";
import icons from "@/core/config/icons";
import { mapState } from "vuex";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import NationalContractModal from "@/view/components/modals/nations/NationalContractModal.vue"
import {backendErrorSwal} from "@/core/helpers/swal";

export default {

  components: {
    Datatable,
    CountryFlag,
    NationalContractModal
  },
  props: {
    nationalContract: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      icons,
      sortDesc: true,
      fields: [
        { label: this.$t("Country"), key: "country", sortable: true, class: "align-middle" },
        { label: this.$t("Name"), key: "name", sortable: true, class: "align-middle" },
        { label: this.$t("Description"), key: "description", sortable: true, class: "align-middle" },
        { label: this.$t("Language"), key: "language", sortable: true, class: "align-middle" },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      total: 0,
      perPage: 10,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: "",
      isSaving: false,
      formApiErrors: null,
      editForm: {},
    };
  },

  computed: {
    ...mapState("constants", ["countries", "languages"]),
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter
      };
    },
    languageSelectable() {
      return this.languages.map(x => ({ text: x.name_en, value: x.iso_639_1 }));
    },

    countrySelectable() {
      return this.countries.map(x => ({ text: x.name, value: x.iso }));
    },
  },

  watch: {
    formApiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          name: [...(val?.name ?? [])],
          description: [...(val?.description ?? [])],
          country: [...(val?.country ?? [])],
          language: [...(val?.language ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    refresh() {
      this.$refs.table.refresh()
    },
    itemProvider(ctx, callback) {
      EmployeeNationalContractService.getAll({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy,
        search: ctx.filter,
        sortDesc: ctx.sortDesc,
        fields: 'id,country,language,description,name'
      })
        .then(res => {
          this.total = res.count;
          callback(res.results);
        })
        .catch(err => {
          backendErrorSwal(err, err?.response?.data?.detail);
          callback([]);
        });

      return null;
    },

    extendedCountryName(iso2) {
      const x = this.countries.find(el => el.iso == iso2);
      if (!x) return iso2;
      return x.name;
    },

    extendedLanguageName(iso2) {
      const x = this.languages.find(el => el.iso_639_1 == iso2);
      if (!x) return iso2;
      return x.name_en;
    },

    editItemClick(id, node) {
      this.showTable = false;
      this.editForm = { ...node };
      this.$bvModal.show(id);
    },

    createItemClick(id) {
      this.editForm = {};
      this.$bvModal.show(id);
    },

    remove(item) {
      Swal.fire({
        title: this.$t("Delete"),
        text: `${this.$t("Are you sure you want to delete")} ${item.name}?`,
        icon: "warning",
        confirmButtonClass: "btn btn-danger",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
        cancelButtonClass: "btn",
      }).then(async res => {
        if (res.isConfirmed) {
          try {
            await EmployeeNationalContractService.delete(item.id);
            this.$refs.table.refresh();
            Swal.fire({
              title: this.$t("Success"),
              icon: "success",
              text: this.$t("National Contract deleted!"),
            });
          } catch (err) {
            backendErrorSwal(err, err?.response?.data?.detail);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.btn.btn-outline-light {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.btn.btn-outline-light i::before {
  color: #fff;
}

.btn.btn-outline-light:hover span,
.btn.btn-outline-light:hover i::before {
  color: var(--primary);
}
</style>