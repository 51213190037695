<template>
  <div class="card card-custom">
    <div class="card-body p-0 pb-2">
      <national-contract-table></national-contract-table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NationalContractTable from "@/view/components/tables/NationalContractTable.vue";

export default {
  components: {
    NationalContractTable,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("National contracts"), route: { name: "national-contract" } },
    ]);
  },
};
</script>
