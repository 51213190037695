var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body p-0 pb-2"
  }, [_c('national-contract-table')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }